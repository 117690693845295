import React, { Component } from "react"
import PropTypes from 'prop-types'

import "./Scale.scss";
const STEP = 0.1;

class Scale extends Component {
    state = {
        valueChanged: false
    };

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);

        if (!this.props.value) {
            // Set default value as 0.5
            this.props.handleChange(this.props.question.id, '0.5');
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown)
    }

    handleKeyDown = (e) => {

        if (e.target.nodeName !== 'INPUT') {
            if (e.defaultPrevented) {
                return
            }

            let handled = false;

            // multiple conditionals for cross browser support

            if (e.key !== undefined) {
                switch (e.key) {
                    case 'ArrowLeft': this.subtract(); break;
                    case 'ArrowRight': this.add(); break;
                    default: ;
                }
                handled = true;
            } else if (e.keyCode !== undefined) {
                switch (e.key) {
                    case 37: this.subtract(); break;
                    case 39: this.add(); break;
                    default: ;
                }
                handled = true;
            }

            if (handled) {
                e.preventDefault();
            }
        }
    }

    subtract = () => {
        let newValue;

        if (this.props.value - STEP < 0)
            newValue = 0;
        else
            newValue = this.props.value - STEP

        this.setState({ value: newValue })
    }

    add = () => {
        let newValue;

        if (this.props.value + STEP > 1)
            newValue = 1;
        else
            newValue = this.props.value + STEP

        this.setState({ value: newValue })
    }

    handleChange = e => {
        // console.log(e.target);
        const value = e.target.value;
        this.setState({
            valueChanged: true
        }, () => {
            this.props.handleChange(this.props.question.id, value)
        });
        // Snap("#svg").circle(90,120,80);
    };

    render() {
        const {
            // question_id,
            // question_type,
            question_description,
            left_of_scale,
            right_of_scale
        } = this.props.question;

        const leftCircles = [], rightCircles = [];

        for (let i = 0; i < 5; i++) {
            leftCircles.push(<span key={i + 'left'} className={`dot ${parseFloat(this.props.value) < (i + 1) * 0.1 ? 'selected pulse' : ''}`}></span>)
            rightCircles.push(<span key={i + 'right'} className={`dot ${parseFloat(this.props.value) >= (i + 1) * 0.1 + 0.5 ? 'selected pulse' : ''}`}></span>)
        }

        return (
            <>
                <div className="description">{question_description}</div>
                <div className="range-slider mb-5 mt-4">
                    <div className="row">
                        <div className="col-12 col-md-4 text-left text-right-md">
                            <div className="dot-container-disagree">
                                {/* <span className="agree-text" style={{fontSize: `${1.5-parseFloat(this.state.value)*0.5}em`}}>Disagree</span> */}
                                <span className={`agree-text ${parseFloat(this.props.value) < 0.5 ? 'text-red' : ''}`}>
                                    {left_of_scale ? left_of_scale : 'Disagree'}
                                </span>
                                <div className="flex-box">
                                {leftCircles}
                                </div>
                                {/* <ProgressRing radius={20} stroke={4} strokeColour='#555AF7' progress={100-this.state.value*100}/> */}
                            </div>
                        </div>
                        <div className="col-12 col-md-4 d-flex justify-content-center">
                            <input
                                className={`range-slider__range ${this.state.valueChanged ? 'selected' : ''}`}
                                type="range"
                                value={parseFloat(this.props.value)}
                                min="0"
                                max="1"
                                step="0.01"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-12 col-md-4 text-right text-left-md">
                            <div className="dot-container-agree">
                                {/* <span className="agree-text" style={{fontSize: `${1+parseFloat(this.state.value)*0.5}em`}}> */}
                                <span className={`agree-text ${parseFloat(this.props.value) > 0.5 ? 'text-red' : ''}`}>
                                    {right_of_scale ? right_of_scale : 'Agree'}
                                </span>
                                <div className="flex-box">
                                    {rightCircles}
                                </div>
                                {/* <ProgressRing radius={20} stroke={4} strokeColour='#555AF7' progress={this.state.value*100}/> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="gradient" style={{backgroundColor: rgba()}}></div> */}

                {/* Type 2 */}

                {/* <div style={{ position: "relative" }}>
          <div className="left-circle-container">
            <div
              className="circle"
              style={{
                transform: `scale(${(1 - this.state.value) * 10}, ${(1 - this.state.value) * 10})`
              }}
            />
          </div>
          <div className="right-circle-container">
            <div
              className="circle"
              style={{
                transform: `scale(${this.state.value * 10}, ${this.state.value * 10})`
              }}
            />
          </div>
        </div> */}

                {/* Type 3 */}

                {/* <div className="left" style={{ transform: `translateX(-${this.state.value*100}%)`}}></div>
        <div className="right" style={{ transform: `translateX(${100-this.state.value*100}%)`}}></div> */}

                {/* <svg className="scale-svg" ref={d => this.svgDiv = d}></svg> */}
            </>
        );
    }
}

Scale.propTypes = {
    value: PropTypes.string.isRequired
}

Scale.defaultProps = {
    value: '0.5',
}

export default Scale;
