import React, { Component } from 'react'
import Tippy from '../../../../../Dialogs/Tippy'
import './TraitScoreBar.scss'

class TraitScoreBar extends Component {

    render() {

        const { trait, hasCompletedQuiz } = this.props
        let { type, trait_score, others } = trait || {}
        type = 'continuous'
        let meanPercentage
        if (others && others.length > 0) {
            meanPercentage = others.reduce((sum, point) => sum + point, 0) / others.length
            meanPercentage = (meanPercentage * 100).toFixed(0)
        }

        let percentage = trait_score ? (trait_score * 100).toFixed(0) : null
        if (trait_score === 0) percentage = 0

        return (
            <>
                {
                    trait.left_label && trait.right_label ?
                        <div className="percentage-labels" id="percentage-labels-web">
                            <span>{trait.left_label}</span>
                            <span>{trait.right_label}</span>
                        </div>
                    : ''
                }
                <div className="trait-score-bar">
                    <div className="trait-name">
                        {trait.trait}
                        <Tippy content={
                            <div className="trait-tooltip">
                                <span className="tippy-trait">{trait.trait}</span><br/>
                                <p className="tippy-description">{trait.description}</p>
                            </div>
                        }>
                            <svg className="icon-info"><use xlinkHref="#tooltip"></use></svg>
                        </Tippy>
                    </div>
                    {
                        trait.left_label && trait.right_label ?
                            <div className="percentage-labels" id="percentage-labels-mobile">
                                <span>{trait.left_label}</span>
                                <span>{trait.right_label}</span>
                            </div>
                        : ''
                    }
                    <div className="trait-score-container">
                        <svg width="100%" height="20px">
                            <g>
                                <line x1="25%" y1="0" x2="25%" y2="20" />
                                <line x1="50%" y1="0" x2="50%" y2="20" />
                                <line x1="75%" y1="0" x2="75%" y2="20" />
                                {percentage !== null &&
                                    <Tippy content={
                                        trait.left_label && trait.right_label 
                                        ?
                                            <>
                                                <span className="tippy-title">Mean</span><br/>
                                                {trait.left_label}<span className="tippy-highlight">{100.0 - meanPercentage}%</span><br/>
                                                {trait.right_label}<span className="tippy-highlight">{meanPercentage}%</span>
                                            </>
                                        :
                                            <>
                                                <span className="tippy-title">Mean</span>
                                                <span className="tippy-highlight">{meanPercentage}%</span>
                                            </>
                                    }>
                                        <line className="mean-line" x1={`${meanPercentage}%`} y1="0" x2={`${meanPercentage}%`} y2="20" />
                                    </Tippy>
                                }
                                {type === 'continuous' && others && others.map((point, index) => {
                                    return (
                                        <g key={`trait-score${index}`}>
                                            <circle cx={`${point * 100}%`} cy="10" r="3" opacity="1"></circle>
                                        </g>
                                    )
                                })}

                                {/* Hide own trait_score if quiz is not completed */}
                                {percentage !== null && hasCompletedQuiz &&
                                    <g>
                                        <Tippy content={
                                            trait.left_label && trait.right_label 
                                            ? 
                                                <>
                                                    {trait.left_label}<span className="tippy-highlight">{100.0 - percentage}%</span><br/>
                                                    {trait.right_label}<span className="tippy-highlight">{percentage}%</span>
                                                </>
                                            : `${percentage}%`}>
                                            <circle className="trait_score self" cx={`${percentage}%`} cy="10" r="5" opacity="1"></circle>
                                        </Tippy>
                                    </g>
                                }
                            </g>
                        </svg>
                    </div>

                    <svg className="hidden">
                        <symbol id="tooltip" viewBox="0 0 512 512">
                            <g id="icomoon-ignore"></g>
                            <path d="M224 152c0-13.2 10.8-24 24-24h16c13.2 0 24 10.8 24 24v16c0 13.2-10.8 24-24 24h-16c-13.2 0-24-10.8-24-24v-16z"></path>
                            <path d="M320 384h-128v-32h32v-96h-32v-32h96v128h32z"></path>
                            <path d="M256 0c-141.385 0-256 114.615-256 256s114.615 256 256 256 256-114.615 256-256-114.615-256-256-256zM256 464c-114.875 0-208-93.125-208-208s93.125-208 208-208 208 93.125 208 208-93.125 208-208 208z"></path>
                        </symbol>
                    </svg>
                </div>
            </>
        )
    }
}

export default TraitScoreBar