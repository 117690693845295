import React, { Component } from 'react'
import TraitScoreBar from './TraitSection/components/TraitScoreBar'
import './TraitSection/TraitSection.scss'
import './TraitSection/components/TraitDescription/TraitDescription.scss'
import './Report.scss'
import '../../../styles/tippy.scss'
import '../demo.scss'

class Report extends Component {

    render() {

        const { hasCompletedQuiz, takeQuiz, traits, name, resetDemo } = this.props

        const output_sequence = [
            'Turnover Risk',
            'Job Satisfaction',
            'Holding Fast Ability',
            'Customer-Orientated Mindset',
            'Business Sensitivity',
            'Solution Building Ability',
            'Analytical Ability',
            'Communication and Listening Ability',
            'Emotion Comprehension Ability',
            'Organization / Individual Centric',
            'Creative / Logical Orientation'
            ]

        return (
            <div id="demo-report">
                <div className="trait-section_section">
                    { hasCompletedQuiz ?
                    // If quiz completed, show name
                    <div className="clearfix">
                        <span>YOUR NAME</span>
                        <a className="btn btn-primary report-name">{name}</a>
                        <button type="button" className="btn clear-results" onClick={resetDemo} >Clear Results</button>
                    </div>
                    :
                    ''
                    }
                    <div style={{paddingTop: '2rem'}}>
                        <div className="percentage-labels">
                            <span>Low - 0%</span>
                            <span>100% - High</span>
                        </div>
                        {
                            traits && output_sequence.map(trait_name => {
                                const trait = traits.find(trait => trait.trait === trait_name)
                                return trait && <TraitScoreBar trait={trait} key={`trait-score-bar${trait_name}`} hasCompletedQuiz={hasCompletedQuiz} />
                            })
                        }
                    </div>
                </div>
            </div>);
    }
}

export default Report