import React from "react"
import Demo from '../components/Demo'
import './solutions.scss'
import FeatureCard from '../components/FeatureCard'
import Filler from '../components/FeatureCard/Filler'
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const CustomizedSolutions = () => {
    
    const data = useStaticQuery(graphql`
	query {
		selfi_screenshot: file(relativePath: { eq: "customized-screenshot.png" }) {
			childImageSharp {
				fluid(maxWidth: 1051, maxHeight: 649) {
				  ...GatsbyImageSharpFluid
				}
			  }
		},
	  }
	`)

    return (
        <div className="solutions">
            <SEO title="Customized Solutions" />
            <section className="bg--tertiary" id="customized-solutions-web">
                <div className="container content solutions-top-container">
                    <div className="col-sm-8 col-md-8 screenshot-container">
                        <div className="selfi-screenshot">
                            <Image
                                fluid={data.selfi_screenshot.childImageSharp.fluid}
                                alt="Customized solutions" />
                        </div>
                    </div>
                    <div className="col-sm-5 col-md-5">
                        <div className="row">
                            <span className="text-gray-light">OUR SOLUTIONS</span>    
                        </div>
                        <div className="row no-logo-margin">
                            <h1 className="solution-title">Customized solutions</h1>
                            <p>Every organization is unique and tackles different problems. What works for one organization might not work on the others.  With a customized solution, we can help you achieve your objectives using our strengths in Psychology and Data Science.</p>
                            <p>Read more about the projects we have worked on below.</p>
                        </div>
                    </div>
                </div>
            </section>
            <div id="customized-solutions-mobile">
                <section className="bg--tertiary" id="customized-solutions">
                    <div className="container content solutions-top-container">
                        <div className="screenshot-container">
                            <div className="selfi-screenshot">
                                <Image
                                    fluid={data.selfi_screenshot.childImageSharp.fluid}
                                    alt="Customized solutions" />
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="row">
                                <span className="text-gray-light">OUR SOLUTIONS</span>    
                            </div>
                            <div className="row">
                                <h1 className="solution-title">Customized solutions</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="selfi-mobile-container" id="customized-solutions">
                    <div className="container content">
                        <div className="selfi-mobile-card">
                            <div className="row">
                                <div className="col-12">
                                    <p>Every organization is unique and tackles different problems. What works for one organization might not work on the others.  With a customized solution, we can help you achieve your objectives using our strengths in Psychology and Data Science.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <section className="bg--tertiary">
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="section-title-secondary-black">What we have worked on</h1>
                        </div>
                    </div>
                    <div className="row features-container">
                        <FeatureCard col={3} title={"Financial risk appetite"} text={"iAdvisor: A digital platform by Jachin Capital enabling investors access to intelligent portfolios. Mercurics integrated our AI Engine to ascertain ﬁnancial risk appetite of individuals, as part of their on-boarding onto the platform. Tuned model can predict an individual's risk appetite with 80% accuracy."} />
                        <FeatureCard col={3} title={"Archetype profiling for targeted interventions"} text={"Archetype Development: Through the use of Natural Language Processing (NLP) and clustering, we made sense of large scale open ended text and demographic data to categorise respondents into specific archetypes. This helped our client to better understand the profiles of each archetype, and enable them to provide targeted interventions for each group."} />
                        <FeatureCard col={3} title={"Staff Operational Readiness"} text={"Fit-for-duty: Understanding staff operational readiness by measuring key factors such as reaction speed, attentiveness and behavioural reliability. Integrated measurement tools into an app."} />
                        <FeatureCard col={3} title={"Employee Understanding"} text={"To accelerate internal talent growth, a large Japanese telecommunications company wanted to enhance their understanding of employee’s psychological and behavioural traits. Mercurics customised a computational model tied with psychometric assessments to ascertain job satisfaction, aspiration, sense of professionalism and overall job fit."} />
                        <FeatureCard col={3} title={"Employee Flight Risk Modelling"} text={"Built a neural network model that determined the likelihood that an assessed employee would leave the company based on personality traits, work environment factors and demographic characteristics. Utilised already available internal employee data combined with customised psychological questionnaires."} />
                        <Filler col={3} title={"Got questions?"} text={"Do you have ideas you want to implement, or simply figuring out how you can utilise People Analytics?"} />
                    </div>
                </div>
            </section>
            {/* <section className='trait-section' id='customized-solutions-web'>
                <div className="container content">
                    <div className="demo-container">
                        <Demo />
                    </div>
                </div>
            </section>
            <section className='trait-section' id='customized-solutions-mobile'>
                <div className="container content">
                    <div className="row">
                        <div className="col-12">
                            <Demo />
                        </div>
                    </div>
                </div>
            </section> */}
        </div>
    )
}

export default CustomizedSolutions