import React from 'react'
import "./Mcq.scss";


const Mcq = (props) => {

    const {
        value,
        question: {
            id,
            question_description,
            options
        },
        handleChange
    } = props;

    
    return (
            <div className="question_mcq-component">
                <div className="description">{question_description}</div>
                    <div className="offset-1 col-sm-10 col-md-10" id="mcq-web">
                        <div className="row">
                            {options && Object.keys(options).map( (index) => {

                                const option = options[index]

                                return <div className="col-sm-6 col-md-6" key={`mcq-${index}`}>
                                    <div className="radio-group" key={index}>
                                        <input id={option.id} type="radio" name={id} value={option.id} onClick={(e) => {
                                            const value = e.target.value;
                                            handleChange(id, value)
                                        }} />
                                        <label htmlFor={option.id} className={`radio ${value == option.id && 'checked'}`}>
                                            <span className="radio-material"></span>{option.option_description}
                                        </label>
                                    </div>
                                </div>
                                

                            })}                  
                        </div>
                    </div>

                    <div id="mcq-mobile">
                        <div className="row">
                            {options && Object.keys(options).map( (index) => {

                                const option = options[index];

                                return <div className="col-sm-6 col-md-6">
                                    <div className="radio-group" key={index}>
                                        <input id={option.id} type="radio" name={id} value={option.id} onClick={(e) => {
                                            const value = e.target.value;
                                            handleChange(id, value)
                                        }} />
                                        <label htmlFor={option.id} className={`radio ${value === option.id && 'checked'}`}>
                                            <span className="radio-material"></span>{option.option_description}
                                        </label>
                                    </div>
                                </div>
                                

                            })}                  
                        </div>
                    </div>
            </div>
    )
}

export default Mcq;
