import React from 'react'
import "./Text.css";


const Text = (props) => {

    const {
        value,
        question: {
            id,
            question_description
        },
        handleChange
    } = props;

    return (
            <div className="question_text-component mb-3">
                <div className="description">{question_description}</div>

                <textarea id={id} className="form-control" rows={3} value={value} onChange={(e) => {
                    const value = e.target.value;
                    handleChange(id, value)
                }} />

            </div>
    )
}

export default Text;
