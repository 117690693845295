import React, { Component } from 'react'
import './NameForm.scss'
import { randomNames } from './RandomNames'

class NameForm extends Component {
    
    generateRandomName = (e) => {
        const { onChangeName } = this.props

        let rand = Math.floor(Math.random() * randomNames.length)
        e.target.value = randomNames[rand]
        onChangeName(e)
    }

    render() {

        const { onChangeName, onBack, beginQuiz, name } = this.props

        return (
            <div className="row">
                <div className="col name-form" id="demo-web">
                    <h4>Enter Name</h4>
                    <p className="description">So you can identify your results. You can use any name you want. It's stored locally and not shared.</p>
                    <input type="text" name="name" onChange={onChangeName} placeholder="Name" value={name} />
                    <button className="generator" onClick={this.generateRandomName} >Generate</button>
                    <div className="buttons-container">
                        <button type="button" onClick={onBack} className="btn btn-previous width-33" >Go back</button>
                        <button className="btn btn-primary js-trigger animation-element width-66" onClick={beginQuiz} disabled={!name || name.length === 0 } >
                            Start quiz!
                        </button>
                    </div>
                </div>
                <div className="col-12 name-form" id="demo-mobile">
                    <h4>Enter Name</h4>
                    <p>So you can identify your results. You can use any name you want. It's stored locally and not shared.</p>
                    <input type="text" name="name" onChange={onChangeName} placeholder="Name" value={name} />
                    <button className="generator" onClick={this.generateRandomName} >Generate</button>
                    <button type="button" onClick={onBack} className="btn btn-previous width-33" >Go back</button>
                    <button className="btn btn-primary js-trigger animation-element width-66" onClick={beginQuiz} disabled={!name || name.length === 0 } >
                        Start quiz!
                    </button>
                </div>
            </div>
        )
    }
}

export default NameForm