import React, { Component } from 'react'
import '../FeatureCard.scss'
import { scrollToNode } from '../../../helpers/scrollToNode'

class Filler extends Component {

    scrollToForm = () => {
		let node = document.getElementById('contact-form-web')
		scrollToNode(node)
	}

    render() {

        const { title, text, col } = this.props
        
        return (
            <div className={"filler-" + col} id="feature-card">
                <span className="filler-title">{title}</span>
                <p>{text}</p>
                <a className="btn btn-primary js-trigger animation-element" onClick={this.scrollToForm}>
                    Contact us today
                </a>
            </div>
        )
    }
}

export default Filler