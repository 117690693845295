import React, { Component } from 'react'
import Report from './Report'
import Questions from './Questions'
import NameForm from './NameForm'
import ErrorMessage from '../Error/ErrorMessage'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner'
import './demo.scss'
import { scrollToDemo } from '../../helpers/scrollToNode'

class Demo extends Component {

    host = 'https://w-api-staging.mercurics.io'
    endpoints = {
        getReport: '/reports/',
        getQuiz: '/quizzes/',
        postAnswers: '/answers/'
    }

    state = {}

    componentDidMount() {
        this.setState(this.getInitialState(), this.loadData);
    }

    getInitialState = () => {
        return {
            traits: [],
            error: false,
            name: '',
            is_entering_name: false,
            quiz_start: false,
            quiz_questions: [],
            current_index: 0,
            values: [],
            report_id: '',
            loading: false
        }
    }

    loadData = () => {
        const name = localStorage.getItem('name') || ''
        const traits = JSON.parse(localStorage.getItem('traits')) || []
        const report_id = localStorage.getItem('report_id') || ''

        // if no data on localStorage, retrieve trait averages for display. Else rehydrate state
        if (traits.length === 0) {
            this.retrieveTraitAverage();
        } else {
            this.setState({
                name: name,
                traits: traits,
                report_id: report_id
            })
        }
    }

    retrieveTraitAverage = () => {
        this.setState({ loading: true })
        fetch(this.host + this.endpoints.getReport + '3dd8cd31-e881-4071-b253-52d7371ef93d')
            .then(response => response.json())
            .then(response => {
                this.setState({ traits: response.results }, this.setState({ loading: false }))
            },
                error => {
                    this.setState({ error: error.message }, this.setState({ loading: false }))
                })
    }

    handleTakeQuizWeb = () => {
        this.setState({ is_entering_name: true }, () => scrollToDemo(document.getElementById("anchor-web")))
    }

    handleTakeQuizMobile = () => {
        this.setState({ is_entering_name: true })
    }

    onChangeName = (e) => {
        this.setState({
            name: e.target.value
        })
    }

    beginQuiz = () => {
        this.setState({
            quiz_start: true,
            report_id: '',
            traits: [],
            is_entering_name: false,
            loading: true
        })
        fetch(this.host + this.endpoints.getQuiz + '1')
            .then(response => response.json())
            .then(response => {
                this.setState({ quiz_questions: response.questions }, this.setState({ loading: false }))
            },
                error => {
                    this.setState({ error: error.message }, this.setState({ loading: false }))
                })
    }

    handleChange = (question_id, newValue) => {
        this.setState(prevState => {
            const newState = JSON.parse(JSON.stringify(prevState))
            const index = newState.values.findIndex(answer => answer.question == question_id)

            if (index > -1) {
                newState.values[index] = {
                    "question": question_id,
                    "value": newValue
                }
            } else {
                newState.values.push({
                    "question": question_id,
                    "value": newValue
                })
            }
            return newState
        })
    }

    handlePreviousQuestion = () => {
        if (this.state.current_index - 1 >= 0) {
            this.setState({
                current_index: this.state.current_index - 1
            })
        }
    }

    handleNextQuestion = () => {
        const { current_index, values } = this.state
        if (current_index < this.state.quiz_questions.length - 1) {
            if (values[current_index] !== undefined) {
                this.setState({ current_index: current_index + 1 })
            }
        }
    }

    handleSubmit = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(this.state.values)
        }
        this.setState({ loading: true })
        // POST quiz answers
        fetch(this.host + this.endpoints.postAnswers, requestOptions)
            .then(response => response.json())
            .then(response => {
                this.setState({
                    report_id: response.report,
                    quiz_start: false
                    // GET quiz report
                }, () => fetch(this.host + this.endpoints.getReport + this.state.report_id)
                    .then(response => response.json())
                    .then(response => {
                        this.setState({ traits: response.results }, this.setState({ loading: false }))
                        localStorage.setItem('traits', JSON.stringify(response.results))
                        localStorage.setItem('name', this.state.name)
                        localStorage.setItem('report_id', this.state.report_id)
                    },
                        error => {
                            this.setState({ error: error.message }, this.setState({ loading: false }))
                        }))
            },
                error => {
                    this.setState({ error: error.message }, this.setState({ loading: false }))
                })
    }

    resetDemo = () => {
        localStorage.clear()
        this.setState(this.getInitialState())
        this.retrieveTraitAverage()
    }

    render() {

        const { is_entering_name, current_index, values, traits, report_id, quiz_questions, quiz_start,
            name, error, loading } = this.state

        let show_questions = quiz_start && quiz_questions.length > 0 ? true : false
        const count = quiz_questions ? quiz_questions.length : undefined

        return (
            <div className="container content demo">

                <h1 className="section-title-secondary-black text-center">Employee Profiling Demo</h1>

                <div className="row">
                    <div className="col-12 offset-sm-2 col-sm-8 text-center">
                        <div className="row" id="anchor-web">
                            <p className="demo-description">This questionnaire aims to supplement an organization’s ability in making informed and strategic decisions, to better identify, attract, develop, and retain talent. <span className="text-primary">Try out this tool and see where you stand relative to other visitors, each represented by a data point below.</span></p>
                        </div>
                        <div className="row mt-4">
                            <div className="take-quiz-btn-container m-auto">
                                {report_id === '' && !error && !loading && !is_entering_name && !show_questions ?
                                        <>
                                            <div id="demo-web">
                                                <button className="btn btn-primary js-trigger animation-element take-quiz-btn" onClick={this.handleTakeQuizWeb} traits={traits} name={name}>
                                                    Take Quiz
                                                </button>
                                            </div>
                                            <div id="demo-mobile">
                                                <button className="btn btn-primary js-trigger animation-element take-quiz-btn" onClick={this.handleTakeQuizMobile} traits={traits} name={name}>
                                                    Take Quiz
                                                </button>
                                            </div>
                                        </>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12" id="demo-web">
                    <div className="row">
                        <div className="col-12">
                            {error ?
                                <ErrorMessage>{error}</ErrorMessage>
                                : loading ?
                                    <center><LoadingSpinner></LoadingSpinner></center>
                                    : is_entering_name ?
                                        <NameForm onChangeName={this.onChangeName}
                                            onBack={() => this.setState({ is_entering_name: false })}
                                            beginQuiz={this.beginQuiz}
                                            name={name} />
                                        : show_questions ?
                                            <Questions current_index={current_index} quiz_questions={quiz_questions} values={values} count={count}
                                                handleChange={this.handleChange} handlePreviousQuestion={this.handlePreviousQuestion}
                                                handleNextQuestion={this.handleNextQuestion} handleSubmit={this.handleSubmit} />
                                            :
                                            <Report hasCompletedQuiz={report_id !== ''} resetDemo={this.resetDemo}
                                                takeQuiz={() => this.setState({ is_entering_name: true })} traits={traits} name={name} />
                            }
                        </div>
                    </div>
                </div>
                <div id="demo-mobile">
                    {error ?
                        <ErrorMessage>{error}</ErrorMessage>
                        : loading ?
                            <center><LoadingSpinner></LoadingSpinner></center>
                            : is_entering_name ?
                                <NameForm onChangeName={this.onChangeName}
                                    onBack={() => this.setState({ is_entering_name: false })}
                                    beginQuiz={this.beginQuiz}
                                    name={name} />
                                : show_questions ?
                                    <Questions current_index={current_index} quiz_questions={quiz_questions} values={values} count={count}
                                        handleChange={this.handleChange} handlePreviousQuestion={this.handlePreviousQuestion}
                                        handleNextQuestion={this.handleNextQuestion} handleSubmit={this.handleSubmit} />
                                    :
                                    <Report hasCompletedQuiz={report_id !== ''} resetDemo={this.resetDemo}
                                        takeQuiz={() => this.setState({ is_entering_name: true })} traits={traits} name={name} />
                    }
                </div>
            </div>
        )
    }
}

export default Demo