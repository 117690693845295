const randomNames = [
    'Angsty Antelope',
    'Lifeless Lizard',
    'Weeping Willow',
    'Pitiful Peacock',
    'Outrageous Ostrich',
    'Sinister Seal',
    'Dainty Dinosaur',
    'Patriotic Panda',
    'Pretentious Pig',
    'Catastrophic Cat',
    'Righteous Rat',
    'Gorgeous Gorilla',
    'Tormented Turtle',
    'Manipulative Monkey',
    'Seasonal Starfish',
    'Kinetic Kangaroo',
    'Addictive Anteater',
    'Flabbergasted Frog',
    'Retired Rabbit',
    'Zesty Zebra',
    'Erroneous Elephant',
    'Inquisitive Iguana',
    'Hysterical Hyena',
    'Jittery Jaguar',
    'Vainglorious Vulture'
]

export { randomNames }