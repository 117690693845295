import React from 'react'

const ErrorMessage = (props) => {

    const { children, className } = props;

    return <span className={`invalid-feedback ${className}`}>Oops! An error occurred. Please try again later or contact us at <u>hello@mercurics.com</u>.</span>
}

ErrorMessage.defaultProps = {
    className: ''
}

export default ErrorMessage;