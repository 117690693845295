import React, { Component } from 'react'
import Scale from './Scale'
import Mcq from './Mcq'
import Text from './Text'
import './Questions.scss'

class Questions extends Component {

    render() {

        const { current_index, quiz_questions, values, handleChange, handlePreviousQuestion, 
            handleNextQuestion, handleSubmit, count } = this.props

        return (
            <div className="questions content-container">
                <div className={'content-area-container loaded'}>
                    <div className="content-area">
                        <form>
                            {/* <center> */}
                            <span className="content-area__number">Question {current_index + 1} of {quiz_questions.length}</span>
                                { quiz_questions.map((question, index) => {
                                    
                                    const type = question.question_type
                                    const value = values[current_index] && values[current_index].value
                                    let selection

                                    switch (type) {
                                        case 'mcq': selection = (
                                            <Mcq 
                                                question={question} 
                                                value={value} 
                                                handleChange={handleChange}
                                                key={`mcq${index}`} />
                                        ); break
                                        case 'scale': selection = (
                                            <Scale 
                                                question={question} 
                                                value={value} 
                                                handleChange={handleChange} 
                                                key={`scale${index}`} />
                                        ); break
                                        case 'text': selection = (
                                            <Text 
                                                question={question} 
                                                value={value} 
                                                handleChange={handleChange}
                                                key={`text${index}`} />
                                        ); break
                                        default: selection = 'No type matched';
                                    }

                                    return question.id === current_index + 1 ? selection : '' 
                                })}

                                <div className="content-area__button">
                                    {current_index > 0 &&
                                        <button type="button" onClick={handlePreviousQuestion} className="btn btn-previous"><span className="icon-left-open"></span></button>
                                    }
                                    {current_index + 1 === count ?
                                        <button type="button" onClick={handleSubmit} className="btn btn-submit" disabled={values[current_index] === undefined}>Submit</button>
                                        : <button type="button" onClick={handleNextQuestion} className="btn btn-next" disabled={values[current_index] === undefined}>Next</button>
                                    }
                                </div>
                            
                            {/* </center> */}
                        </form>
                    </div>
                </div>
            </div>
        );
    }

}

export default Questions